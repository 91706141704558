import React from "react"
import { Row, Col } from "antd"
import "antd/lib/grid/style/index.css"
import styled from "styled-components"
import Layout from "../../components/Layout"

const Img = styled.img`
  max-height: 300px;
  max-width: 300px;
  border-radius: 6px;
`
const buttonStyles = {
  fontSize: "13px",
  textAlign: "center",
  color: "#fff",
  outline: "none",
  padding: "12px 60px",
  boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
  backgroundColor: "rgb(255, 178, 56)",
  borderRadius: "6px",
  letterSpacing: "1.5px",
}
const Checkout = class extends React.Component {
  // Initialise Stripe.js with your publishable key.
  // You can find your key in the Dashboard:
  // https://dashboard.stripe.com/account/apikeys
  componentDidMount() {
    this.stripe = window.Stripe(`${process.env.STRIPE_PUBLIC_KEY}`)
  }
  async redirectToCheckout(event) {
    event.preventDefault()
    const { error } = await this.stripe.redirectToCheckout({
      items: [
        { sku: "sku_Fvln12IU8PJh6R", quantity: 3 },
        { plan: "plan_Fvo6ZjlFJjOdHP", quantity: 1 },
      ],
      successUrl: `http://localhost:8000/page-2/`,
      cancelUrl: `http://localhost:8000/`,
    })
    if (error) {
      console.warn("Error:", error)
    }
  }
  render() {
    return (
      <Layout
        title={"Life Reflections Workbook"}
        subtitle={"Your Legacy of Values"}
        showCta={false}
      >
        <br />
        <br />
        <br />

        <div>
          <Row>
            <Col span={8}>
              <Img
                src="https://d1wqzb5bdbcre6.cloudfront.net/e74234d2fc51eb282c5a946ff77fb1649f670742/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f666c5f746573745f67645849574b6a31307172325332683758336e6b4a303542"
                alt="Product"
                className="ProductImage-image"
              />
              <br />
              <button
                className="button button-primary button-shadow"
                onClick={event => this.redirectToCheckout(event)}
              >
                Purchase Workbook - $14.99 + S/H
              </button>
            </Col>

            <Col span={16}>
              <p>
                This is a practical and easy-to-use workbook to guide you
                through the process of creating your Life Reflection Story.
              </p>

              <p>
                Information includes what Life Reflection Stories are, how they
                differ from memoirs and other types of story writing, why and
                when to write one, and most importantly, how to write yours.
              </p>

              <p>
                Special attention is given to why Life Reflection Stories can
                help you find greater significance and purpose in your life as
                well as share real life examples to help your loved ones as they
                travel along their life journeys.
              </p>

              <p>
                Writing your Life Reflection Story and sharing it with your
                loved ones and future generations provides them with a cherished
                gift, the value of which can’t be quantified. You may preserve
                your completed document as you wish or our Preservation Team is
                available to help you edit, design and print your Life
                Reflection Story as a beautiful keepsake. We provide many
                options to choose from or call for custom designs.
              </p>
            </Col>
          </Row>
          <br />
        </div>
      </Layout>
    )
  }
}
export default Checkout
